body {
	margin: 0;
	font-family: Arial, Helvetica, sans-serif;
	color: #221f26;
	background-color: #f2f3f1;
}

.topnav {
	overflow: hidden;
	background-color: #f7d600;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.topnav-right {
	float: right;
}

.topnav a {
	float: left;
	display: block;
	color: #221f26;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
	font-size: 17px;
}

.topnav a:hover {
	background-color: #221f26;
	color: #f2f3f1;
}

.topnav a.active {
	background-color: #4caf50;
	color: white;
}

.topnav .icon {
	display: none;
}

.page-container {
	position: relative;
	min-height: 100vh;
}

.content-wrap {
	padding-bottom: 3rem; /* Footer height */
	width: 100%;
}

footer {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 3rem; /* Footer height */
	background-color: #221f26;
	color: #f2f3f1;
	text-align: center;
}

.inicio {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.inicio svg {
	padding: 2em;
}

.map {
	display: flex;
	flex-direction: column;
	flex: 100%;
	margin-left: 2em;
	margin-right: 2em;
	max-width: 100%;
	text-align: center;
}

.map.center {
	align-items: center;
}

.map-info {
	margin-top: 1em;
	margin-bottom: 2em;
}

.map-info label {
	background-color: #5f8fc8;
	color: #f2f3f1;
	padding: 8px 16px;
	font-weight: bold;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
}

select {
	color: #221f26;
	border: none !important;
	padding: 8px 16px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	font-size: 1em;
	border-radius: 4px;
	background-color: #ffffff;
}

div.card {
	display: flex;
	flex: 40%;
	max-width: 40%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #e8e9e8;
	margin: auto;
	margin-top: 2em;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	/* width: 60%; */
	padding: 1em;
	text-align: justify;
	font-size: 1.3em;
}

h1.card {
	margin-bottom: 0;
}

.barra {
	background-color: #5f8fc8;
	color: white;
	width: 80%;
	height: 0.4em;
}

.text-break {
	word-break: break-all;
}

.card a {
	color: #221f26;
	padding: 14px 16px;
	text-decoration: none;
}

.card a:hover {
	background-color: #221f26;
	color: #f2f3f1;
}

.shadow {
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 600px) {
	.map {
		margin-left: 0;
		margin-right: 0;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	}
	div.card {
		flex: 100%;
		max-width: 100%;
	}
}
